import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Plan from "../components/Plan"
import ContentImage from "../components/ContentImage"
import SampleWorksSwiper from "../components/SampleWorksSwiper"

// const logo = [
//   {
//     name: 'Jugs',
//     image: require('../assets/images/brands-jugs.png')
//   }, {
//     name: 'Life Grip',
//     image: require('../assets/svg/brands-lifegrip.svg')
//   }, {
//     name: 'Fugoo',
//     image: require('../assets/svg/brands-fugooo.svg')
//   }, {
//     name: 'On Target',
//     image: require('../assets/svg/brands-ontarget.svg')
//   }
// ]

const logoFeat = [
  {
    name: 'Gatsby',
    image: require('../assets/svg/gatsby-1.svg'),
    link: 'https://www.gatsbyjs.org/showcase/fullstackhq.com',
    activeLink: true
  }, {
    name: 'Awards',
    image: require('../assets/svg/brands-awwwards-logo.svg'),
    link: 'https://www.awwwards.com/sites/psd-wizard-1',
    activeLink: true
  }, {
    name: 'Css Design Awards',
    image: require('../assets/svg/brands-cssdesign-logo.svg'),
    link: 'https://www.cssdesignawards.com/sites/psd-wizard/32529/',
    activeLink: true
  }, {
    name: 'JAMStack',
    image: require('../assets/svg/brands-jamstack-logo.svg'),
    link: 'https://jamstack.org/examples/',
    activeLink: true
  }, {
    name: 'React Static',
    image: require('../assets/svg/brands-react-logo.svg'),
    link: 'https://github.com/react-static/react-static#sites-built-with-react-static',
    activeLink: true
  }
]

const servicesInfo = [
  {
    image: require('../assets/images/services-img7.png'),
    desc: 'Fix problems fast'
  }, {
    image: require('../assets/images/services-img8.png'),
    desc: 'Theme layout tweaks and configurations to save you a ton of time'
  }, {
    image: require('../assets/images/services-img9.png'),
    desc: 'Speed optimization to boost conversion and Google ranking'
  }, {
    image: require('../assets/images/services-img10.png'),
    desc: 'Small on-page SEO fixes like adding meta tags'
  }, {
    image: require('../assets/images/services-img11.png'),
    desc: 'Quick BigCommerce app configuration'
  }, {
    image: require('../assets/images/services-img12.png'),
    desc: 'BigCommerce app recommendations'
  }, {
    image: require('../assets/images/services-img13.png'),
    desc: 'Ongoing maintenance (theme and BigCommerce app updates, cleanup)'
  }, {
    image: require('../assets/images/services-img14.png'),
    desc: 'Consultation on web development and other related matters'
  }, {
    image: require('../assets/images/services-img15.png'),
    desc: 'Monthly report of work performed'
  }
]

const plansInfo = [
  {
    image: require('../assets/images/single-task.png'),
    title: 'Maintenance',
    subtitle: 'Give it a try!',
    price: '400/mo',
    type: 'perTask',
    desc: [
      {
        listInfo: '20-hour credit balance',
        description:
        <p>
         You will be given 20-hour credits to use every month. Your credit balance will rollover
and remain available as long as your subscription remains active. Upon cancellation, these
credits will expire. If you wish to preserve your credit balance, you may keep your account under
a maintenance plan for $400/month.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
	     {
        listInfo: 'Updating of graphics, text, and code',
      },
	  {
        listInfo: 'Page speed optimization',
      },
	  {
        listInfo: 'Minor bug fixes',
      },  {
        listInfo: 'Covers one store',
		  description:
        <p>
         Your maintenance subscription and the credits you receive monthly, covers one BigCommerce store.
        </p>
      }, 
	  {
        listInfo: 'Monthly report',
		  description:
        <p>
         Monthly report of work performed and a summary for each service.
        </p>
      },
    ],
    buttonText: 'Buy 20 hours',
	variant: 'outline',
    buttonLink: 'https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-7TU50290J52525636ML2LTTA',
    buttonBeacon: false,
    buttonExternal: true,
    buttonNextTab: false,
  },
  {
    image: require('../assets/images/unlimited-task.png'),
    title: 'Full Stack',
    subtitle: 'Subscription',
    price: '720',
    range: true,
    type: 'number',
    desc: [
      {
        listInfo: 'from 40 hours credit balance',
        description:
        <p>
         Our Pay As You Go plans are considered prepaid blocks of hours. If you do not use all your hours in one month, they are rolled over to the next month.
        </p>
      },
      {
        listInfo: 'No upfront cost, 7-day trial work',
        description:
        <p>
          The 7-day trial gives you access to our battle-tested team. You decide if you want to continue after the trial.
        </p>
      },
      {
        listInfo: 'Development and design work',
      },
      {
        listInfo: 'Dedicated team',
        description:
        <p>
          Work with a project manager and a specialized developer/designer tailored to your needs.
        </p>
      },
      {
        listInfo: 'Supports multiple projects',
      },
      {
        listInfo: 'Real-time collaboration via',
        listLogo: 'true',
        listLogoImage: require('../assets/svg/slack.svg'),
        listLogoName: 'slack'
      },
      {
        listInfo: 'White-label option',
        description:
        <p>
          Branded to your agency with not a mention of Fullstack HQ anywhere.
        </p>
      },
      {
        listInfo: 'Upgrade, downgrade, pause or cancel anytime',
        description:
        <p>
          You can upgrade or downgrade your subscription at any time by contacting us via chat or email. There is no contract for the subscription; you are free to cancel and re-subscribe whenever you want.
        </p>
      }
    ],
    buttonText: 'Learn more',
    variant: 'normal',
    buttonLink: '/development',
    buttonBeacon: false,
    buttonExternal: false,
    buttonNextTab: false,
  }
]

const testimonyList = [
  {
    text: "Wow. U guys are simply amazing. This design is stunnning. Your design team rocks @Peng! They nailed it first go. Quite impressive",
    image: require('../assets/images/eddie-machaalani.png'),
    name: 'Eddie Machaalani',
    position: 'BigCommerce, Co-founder'
  },
  {
    text: `I was incredibly impressed by how you matched specs with clean code. And the team is super easy to work with!`,
    image: require('../assets/images/mohammed-abid.png'),
    name: 'Mohammed Abid',
    position: 'Facebook, Product Designer'
  }, {
    text: `Wanted to say I love my 2 sites. They are great. The site gets a ton of conversions. I'd say at least half my phone calls are form the website call now buttons.`,
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'AAR Auto Glass, Owner'
  }
]

const worksSwiperContent = [
  {
    comment: "So far we've been having a wonderful experience working with Fullstack for both EventBuilder and Jugs Sports. Your communication is succinct and things are getting done. We're excited to soon start the Copious internal website, as well partner with you again for future projects. With that said, I wanted to brig something to your attention. Mary has been doing a great job and is fast to communicate, which we love.",
    long: true,
    slideItem: 'slideOne',
    avatar: require('../assets/images/jeff-bronson.png'),
    name: "Jeff Bronson",
    position: "CX Strategist, Copious",
    projectImage: require('../assets/images/sample-projects-14.png'),
    link: 'https://jugssports.com/',
    external: true
  },
  {
    comment: "Great Mary, the site is looking awesome!!! I’m stoked!",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/adam-phillips.png'),
    name: "Adam Phillips",
    position: "Founder, LifeGrip",
    projectImage: require('../assets/images/sample-projects-15.png'),
    link: 'https://lifegrip.com.au/',
    external: true
  },
  {
    comment: "Great job in getting this new page done. I love the work you have put in for getting this quickly Thank you so much for the assistance. GREAT JOB",
    long: false,
    slideItem: 'slideTwo',
    avatar: require('../assets/images/fugoo-logo.png'),
    name: "(Confidential)",
    projectImage: require('../assets/images/sample-projects-16.png'),
    link: 'https://www.fugoo.com/',
    external: true
  },
]

const cta = {
  hasBackground: false,
  hasButton: true,
  imagePrimary: true,
  title: 'Work with a battle-tested BigCommerce Expert',
  desc: `Focus on growing your business. Leave all your development needs to Fullstack HQ.`,
  image: require('../assets/images/mascot-bigcommerce.png'),
  hasImage: true,
  logoImage: require('../assets/svg/bigcommerce-partners.svg'),
  logoName: 'BigCommerce',
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function BigCommerce() {
  return (
    <>
      <OgTags
        ogTitle="Outsource BigCommerce Developer help by Fullstack HQ"
        ogDescription="Improve your site’s functionality and rest easy with Fullstack HQ&#39;s BigCommerce Premium Support."
        ogUrl="https://fullstackhq.com/bigcommerce-help"
      />
      <Header />
      <main className="maintenance-landing initialized-header">
        <ContentImage
          content={cta}
        />
        <Testimonials
          title="Loved by world-class entrepreneurs & tech companies"
          testimony={testimonyList}
        />
        <Brands
          text="We've been featured on"
          brands={logoFeat}
        />
        <Services
          heading="What we do"
          services={servicesInfo}
        />
        <SampleWorksSwiper
          heading="Selected sample works"
          worksSwiper ={worksSwiperContent}
        />
        <Plan
          heading="Simple pricing for your BigCommerce needs"
          content={plansInfo}
        />
      </main>
      <Footer />
    </>
  )
}

export default BigCommerce
